
import Vue from "vue";

export default Vue.extend({
  name: "ReportUserModal",
  props: {
    user: Object
  },
  data() {
    return {
      data: {
        message: ""
      },
      btnLoading: false,
      errors: {} as any
    };
  },
  methods: {
    onSubmit() {
      this.btnLoading = true;

      this.$store
        .dispatch(`${this.user.type}/POST_REPORT_USER`, {
          ...this.data,
          // id: this.$route.params.id
          id: this.user.uuid
        })
        .then(message => {
          this.btnLoading = false;
          // @ts-ignore
          this.$alertify.success(message);
          this.errors = {};
          this.data = {
            message: ""
          };
          this.$bvModal.hide("report-user");
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
